import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Text from "../../components/data-display/text";
import { auth } from "../../utils/firebase.utils";
import FormInput from "../../components/forms/form-input";
import FormWrapper from "../../components/forms/form-wrapper";
import FormErrorText from "../../components/data-display/form-error-text";
import AuthContainer from "../../components/data-display/AuthContainer";
import Button from "../../components/buttons/primary-button";
import InlineLink from "../../components/data-display/inline-link";
import SEO from "../../components/seo/seo";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import useTheme from "@material-ui/core/styles/useTheme";
import { baseUrl } from "../../utils/constant.urls";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    background: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
        [theme.breakpoints.down("md")]: {
            justifyContent: "flex-start",
        },
    },
    loading: {
        color: theme.palette.primary.main,
    },
}));

const ForgotPassword = () => {

    const classes = useStyles();
    const theme = useTheme();
    const [success, setSuccess] = useState(false);

    const sendEmail = (values: Record<string, any>) => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${baseUrl}/sendForgotPasswordEmail`,
                    {
                        email: values.email
                    }
                )
                .then(() => {
                    resolve('success')
                    console.log('success')
                    setSuccess(true);
                })
                .catch(async (err) => {
                    console.log(err.code);
                    switch (err.code) {
                        case "auth/user-not-found":
                            return reject({ email: "Couldn't find your account." });
                        case "auth/invalid-email":
                            return reject({ email: "Please enter a valid Email Address." });
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        });
    };

    return (
        <>
            <SEO title={'Sydetasker - Post. Hire. Done.'} pathname={"/forget-password"} description={'Any task you need done? Sydetasker easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, yard work, & more.'} />
            <div className={classes.background}>
                <div style={{ height: "64px" }} />
                <Grid container justify={"center"} component={"div"}>
                    <Grid component={"div"} xl={5} lg={5} md={6} sm={6} xs={12} item style={{ display: "flex", justifyContent: "center" }}>
                        <AuthContainer padding>
                            {!success &&
                                <div style={{ justifyContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                    <FormWrapper initialValues={{ email: "" }} onSubmit={sendEmail}>
                                        <Text variant={"caption"} bold component={"span"}>
                                            <InlineLink to={"/login"} style={{ color: "grey", display: "flex", alignItems: "center" }} variant={"secondary"}>
                                                <FiArrowLeftCircle size={12} style={{ marginRight: "8px", transform: "translateY(2px)" }} />Return to Sign In
                                            </InlineLink>
                                        </Text>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "space-between",
                                            marginBottom: "12px",
                                            marginTop: "24px",
                                        }}>
                                            <Text variant={"h5"}>Forgotten Password</Text>
                                        </div>
                                        <Text variant={"body2"} medium style={{ opacity: "0.6", marginBottom: "8px", lineHeight: "1.2" }}>
                                            No worries, we all forget sometimes. Enter your email to reset your password.
                                        </Text>
                                        <FormInput placeholder={"Enter your email"} name={"email"} type={"email"} />
                                        <FormErrorText />
                                        <Button type="submit" key={"button5"} style={{ marginTop: "24px" }}>
                                            {" "}Continue{" "}
                                        </Button>
                                    </FormWrapper>
                                </div>
                            }
                            {success &&
                                <div style={{ justifyContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                    <FormWrapper onSubmit={sendEmail}>
                                        <Text variant={"caption"} bold component={"span"}>
                                            <InlineLink to={"/login"} style={{ color: "grey", display: "flex", alignItems: "center" }} variant={"secondary"}>
                                                <FiArrowLeftCircle size={12} style={{ marginRight: "8px", transform: "translateY(2px)" }} />Return to Sign In
                                            </InlineLink>
                                        </Text>
                                        <div style={{ justifyContent: "center", marginTop: "24px", width: "100%", display: "flex" }}>
                                            <AiFillCheckCircle size={40} color={theme.palette.primary.main} />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "space-between",
                                            marginBottom: "12px",
                                            marginTop: "24px",
                                        }}>
                                            <Text variant={"h5"}>We sent an email with a link to Reset your Password</Text>
                                        </div>
                                    </FormWrapper>
                                </div>
                            }
                        </AuthContainer>
                    </Grid>
                </Grid>
                <div style={{ height: "64px" }} />
            </div>
        </>
    );
};
export default ForgotPassword;
